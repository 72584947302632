let infoPanels = document.getElementsByClassName("info-panel");
if(infoPanels) {
    for(infoPanel of infoPanels) {	
        infoPanel.addEventListener("click", accordion);
    }

    function accordion(e) {
        let trigger = e.currentTarget; 
        trigger.classList.toggle("active");
        let moreInfoElement = trigger.querySelector(".more-info");
        let moreInfoAttribute = moreInfoElement.getAttribute("data-expand");
        let moreInfoParagraphHeight = (trigger.querySelector(".more-info p").offsetHeight)+20; 

        if( moreInfoAttribute == "expanded") {
            moreInfoElement.style.maxHeight =  "0px";
            moreInfoElement.removeAttribute("data-expand");
        }else {       
            moreInfoElement.style.maxHeight =  `${moreInfoParagraphHeight}px`;
            moreInfoElement.setAttribute("data-expand", "expanded");
        }
    }
}
// Checking if cart order is made/cart is empty/ to remove the cookie with disabled products
addOutOfStock();

// Checking if products have been added to cart/cookie with the ids exist/ and should be disabled
disableOutStockProducts();

let ajaxAddToCarts = document.getElementsByClassName("ajax_add_to_cart");

// attaching the eventListeners
if (ajaxAddToCarts) {
  for (let ajaxAddToCart of ajaxAddToCarts) {
    ajaxAddToCart.addEventListener("click", handleAjaxAddToCart);
  }
}

function handleAjaxAddToCart(e) {
  e.preventDefault();
  // making the GET query
  let trigger = e.currentTarget;
  let url = trigger.getAttribute("href");
  fetch(url).then(updateCart(trigger));
}

function updateCart(trigger) {

// create notify add to cart element
let addedNotifyElement = document.createElement("div");
addedNotifyElement.classList.add("added-to-cart");
addedNotifyElement.innerText = "Добавено в количката";



  let currentProcuctContainer = trigger.parentElement;
  let stockQuantityElement = currentProcuctContainer.querySelector(
    "#stockQuantity"
  );
  let quantity = parseInt(stockQuantityElement.textContent);
  // creating out of stock notify
  let outStock = document.createElement("div");
  outStock.classList.add("out-stock");
  outStock.innerText = "Изчерпан";

  // creating overlay div to disable the element click
  let overlayDiv = document.createElement("div");
  overlayDiv.classList.add("overlay");
  // updating the cart items number in cart badge
  let addToCartBadge = document.querySelector(".shopping-cart .badge");
  console.log(addToCartBadge.innerText);
  if(addToCartBadge.innerText=="") {
    addToCartBadge.innerText = 0;
  }
  let UpdatedAddToCartBadgeText = parseInt(addToCartBadge.innerText) + 1;
  addToCartBadge.innerText = UpdatedAddToCartBadgeText;

  // "disable" the item while updating everithyng - Showing the notification label on adding product
  currentProcuctContainer.appendChild(addedNotifyElement);
  currentProcuctContainer.appendChild(overlayDiv);

  // update the stock quantity in the product hidden html element
  quantity--;
  stockQuantityElement.textContent = quantity;

  // if the product is out of stock - setting its id in cookie
  if (quantity == 0) {
    let productId = trigger.getAttribute("data-product_id");
    let prevProductsIds = getCookieValue("product_id");
    // chekcing if cookie exists / have previous product ids in the cookie and combine them
    if (prevProductsIds) {
      document.cookie = `product_id= ${productId}, ${prevProductsIds}; path=/`;
    } else {
      document.cookie = `product_id= ${productId}; path=/`;
    }

    // if product out of stock remove the add to cart notification and "disable" the item
    setTimeout(() => {
      currentProcuctContainer.querySelector(".added-to-cart").remove();
      currentProcuctContainer.appendChild(outStock);
      disableOutStockProducts();
    }, 2000);
  } else {
    // remove the add to cart notification and enable the product
    setTimeout(() => {
      currentProcuctContainer.querySelector(".added-to-cart").remove();
      currentProcuctContainer.querySelector(".overlay").remove();
    }, 2000);
  }
}

// Checking if cart order is made/cart is empty/ to remove the cookie with disabled products
function addOutOfStock() {
  let cartPage = document.getElementById("cart");
  if (!getCookieValue("woocommerce_items_in_cart")) {
    removeCookie("product_id");
  }

  if (!getCookieValue("product_id")) {
    return;
  }
}
// Checking if products have been added to cart/cookie with the ids exist/ and should be disabled
function disableOutStockProducts() {
  let cartPage = document.getElementById("cart");
  let outStockCookieValue = getCookieValue("product_id");
  if (!outStockCookieValue || cartPage) {
    return;
  }

  let outStockIds = getCookieValue("product_id").split(", ");
  for (let outStockId of outStockIds) {   

   

    outStockElements = document.querySelectorAll(
      `[data-product_id="${outStockId}"]`
    );
    for (let outStockElement of outStockElements) {
       // creating out of stock notify
    let productContainer = outStockElement.parentElement;
    // creatig outStock notify element 
    let outStock = document.createElement("div");
    outStock.classList.add("out-stock");
    outStock.innerText = "Изчерпан";
     // creating overlay div
     let overlayAnchor = document.createElement("a");
     overlayAnchor.classList.add("overlay");   
    let link = productContainer.querySelector(".woocommerce-loop-product__link").getAttribute("href");
    overlayAnchor.setAttribute("href", link);
   
    // appending the elements to out of stock products 
    productContainer.appendChild(outStock);
    productContainer.appendChild(overlayAnchor);
    }
  }
}

// get the value of cookie
function getCookieValue(cookieName) {
  let documentCookies = document.cookie;
  documentCookies = documentCookies.split("; ");
  let cookies = {};
  for (let i = 0; i < documentCookies.length; i++) {
    let cur = documentCookies[i].split("=");
    cookies[cur[0]] = cur[1];
  }

  return cookies[cookieName];
}

// remove cookie
function removeCookie(cookieName) {
  document.cookie = `${cookieName}=; Max-Age=0`;
}

// Remove the id from cookie on remove product in cart;
let cartRemoveElements = document.querySelectorAll(".product-remove .remove");

// attaching the eventListeners
if (cartRemoveElements) {
  for (let cartRemoveElement of cartRemoveElements) {
    cartRemoveElement.addEventListener("click", handleRemoveCookieValue);
  }
}

function handleRemoveCookieValue(e) {
  let trigger = e.currentTarget;
  let productIdRemove = trigger.getAttribute("data-product_id");
  let cookieValue = getCookieValue("product_id").replace(productIdRemove, "");
  document.cookie = `product_id= ${cookieValue};path=/`;
}

function animations() {
    let elements = document.querySelectorAll('.animation');
    if(!elements) {
        return;
    }
    let elementTop;
    let windowTop;
    let elementBottom;
    let windowBottom
    for (let i = 0; i < elements.length; i++) {
        thisAnimationClass = elements[i].getAttribute('data-animation');
        elementTop = elements[i].offsetTop;
        windowTop = document.documentElement.scrollTop;
        elementBottom = elements[i].offsetTop + elements[i].offsetHeight;
        windowBottom = window.innerHeight + window.scrollY;

        if (elementTop > windowTop && windowBottom > elementBottom-window.screen.availHeight - 100) {
            elements[i].classList.add(thisAnimationClass);
        }
        else {
            elements[i].classList.remove(thisAnimationClass);
        }

    }
}
animations();
window.addEventListener("scroll", function(){
    animations();
});
    

function carousel() {
  let scrollContainer = document.querySelector(".scroll-container");
  let controlsContainer = document.querySelector(".controls-container");
  
  if (!scrollContainer || controlsContainer.classList.contains("hidden")) {
    return;
  }

  let controls = controlsContainer.getElementsByTagName("span");
  let colWidth = scrollContainer.querySelector(".col").offsetWidth;
  let scrollContainerWidth = scrollContainer.clientWidth;
  elenetsInVew = Math.floor(scrollContainerWidth / colWidth);

  for (let control of controls) {
    control.addEventListener("click", scrollIt);
  }

  function scrollIt(e) {
    let trigger = e.currentTarget;
    let triggerIndex = [].slice.call(controls).indexOf(trigger);
    let startPoint = scrollContainer.scrollLeft;

    if (triggerIndex == 0) {
      triggerIndex = -1;
    }    

    scrollContainer.scrollTo({
      top: 0,
      left: startPoint + triggerIndex * (elenetsInVew * colWidth),
      behavior: "smooth",
    });
  }
}

window.addEventListener("load", carousel);


let closeBtn = document.querySelector(".privacy-cookies__close");
let privacyWindow = document.querySelector(".privacy-cookies");

if(document.cookie.indexOf('privacyCookie=')==-1) {
    privacyWindow.classList.remove("hidden");
}

closeBtn.addEventListener("click", function(){
    document.cookie = "privacyCookie=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
    privacyWindow.classList.add("hidden");

});
let submit  = document.getElementById("submit-js");

if(submit) {
    submit.innerHTML = '<button name="submit" class="btn contact-form__submit-button align--center" type="submit">Изпрати съобщение</button>';
}
let searchInput = document.getElementById("search");
let searchResults = document.getElementById("searchResults");
let link;
let wholeScreenButton = document.getElementById("loader");
searchInput.addEventListener("keyup", delay(function(e) { 
    wholeScreenButton.classList.remove("loader");   
    link = searchInput.value;
    if (link === "") {
        searchResults.innerHTML = "";
        return;
    }

    fetch(`https://youworthitbox.com/?s=${link}`)
        .then(function(response) {
            // When the page is loaded convert it to text
            return response.text()
        })
        .then(function(html) {
            // Initialize the DOM parser
            var parser = new DOMParser();
            // Parse the text
            var doc = parser.parseFromString(html, "text/html");
            // You can now even select part of that html as you would in the regular DOM 
            // Example:
            let productsContainer = doc.querySelectorAll('.result-container');
            searchResults.innerHTML = "";
            for (let i = 0; i < productsContainer.length; i++) {
                searchResults.appendChild(productsContainer[i]);
            }
            if(searchResults.childNodes.length == 0) {
                searchResults.innerText = "Няма намерени резултати по зададените критерии"
            }
        })
        .catch(function(err) {
            console.log('Failed to fetch page: ', err);
        });
}, 500));


function delay(callback, ms) {
    var timer = 0;
    return function() {        
        var context = this,
            args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function() {
            callback.apply(context, args);
        }, ms || 0);
        wholeScreenButton.classList.add("loader");
    };
}
let mobileMenu = document.querySelector('.mobile-menu');

mobileMenu.addEventListener('click', function(){
    document.querySelector('.site-menu').classList.toggle('mobile-hidden');

    // icon changing 
    let menuIcon = document.querySelector('.mobile-menu i');
    if(menuIcon.classList.contains("fa-bars")) {
        menuIcon.classList.remove("fa-bars");
        menuIcon.classList.add("fa-times");
    }

    else {
        menuIcon.classList.remove("fa-times");
        menuIcon.classList.add("fa-bars");
    }
});
;(function(window, document) {

    const doc = document;
    
    const faders = doc.querySelectorAll(".fade");

    setTimeout(() => {
        for(const fader of faders) {        
            fader.remove();
        } 
        const queryString = window.location.search;
        const parameters = new URLSearchParams(queryString);
        const paidParam = parameters.get('paid');
        
        if(paidParam) {
            location.href = "/";
        }
    }, 7000);
    


})(window, document);
  // adding the text of the buttons 
  let textOfButtonOpened = "Скрий ↑";
  let textOfButtonClosed = "Виж още ↓";
  // /. text of buttons 

  let readMoreDiv = document.querySelectorAll(".semantic-read-more");


  // add the gradient and button to the div
  for (let i = 0; i < readMoreDiv.length; i++) {
      readMoreDiv[i].style.maxHeight = "77px";
      // create the gradient div 
      let gradient = document.createElement("div");
      gradient.classList.add(["semantic"], ["semantic-gradient"], ["position--absolute"]);

      // create the read more button 
      let readMoreButton = document.createElement("span");
      readMoreButton.classList.add(["semantic-expand"], ["position--absolute"]);
      readMoreButton.innerHTML = textOfButtonClosed;

      // adding  the click function on button 
      readMoreButton.addEventListener("click", function () {
          // changing the text of button 
          if (this.innerHTML == textOfButtonClosed) {
              this.innerHTML = textOfButtonOpened;
          }

          else {
              this.innerHTML = textOfButtonClosed;
          }


          // toggle the position 
          this.classList.toggle("position--absolute");

          // toggle the overflow 
          let theParentChild = this.parentElement.firstChild.nextSibling;
          theParentChild.classList.toggle("overflow--hidden");
          
          //   animate div 
            theParentChild.classList.toggle("down-animation")
        // /. animate div 

          /* changing the div height. Changing the max-height with js, because of the animation.
          Animation with max-height should have exact pixels. For example /heParentChild.scrollHeight - 500px/ 
          */
          if (theParentChild.style.maxHeight == "77px") {
              theParentChild.style.maxHeight = theParentChild.scrollHeight + "px";
          }

          else {
              theParentChild.style.maxHeight = "77px";
          }


          // toggle the gradient 
          let theGradient = theParentChild.querySelector(".semantic");
          theGradient.classList.toggle("semantic-gradient");

      });

      // end of the click event 

      // adding the gradient and the readmore button 
      readMoreDiv[i].appendChild(gradient);
      readMoreDiv[i].parentNode.appendChild(readMoreButton);
  }
const showPresentCard = document.getElementById("showPresentCard");

if(showPresentCard) {
    showPresentCard.addEventListener("change", showInputWish);
}

function showInputWish(e) {
    const trigger = e.currentTarget;
    let writeCardContainer = trigger.closest(".show-present-card-container").querySelector(".write-card-container");
    if(trigger.checked) {        
        writeCardContainer.classList.remove("hidden");
    }else {
        writeCardContainer.classList.add("hidden");
    }
}
let searchBar = document.getElementById("search-container");
let searchFormClose = document.getElementById("searchFormClose");
let searchForm = document.querySelector(".search-form");
if(searchBar) {
    searchBar.addEventListener("click", showSearchBar);
    searchFormClose.addEventListener("click" , closeSearchBar);
    
}

function showSearchBar() {
    searchForm.classList.remove("hidden");
}

function closeSearchBar() {
    setTimeout(() => {
        searchForm.classList.add("hidden");
      }, 100);       
    }


window.addEventListener("scroll", stickyElements)

function stickyElements() {
    let topArrow = document.querySelector(".back-to-top");
    let topHeader = document.querySelector(".top-header");
    if (document.body.scrollTop > 400 || document.documentElement.scrollTop > 400) {
        topArrow.classList.remove("hidden");
        topHeader.classList.add("sticky-header")
    } 

    else { 
        topArrow.classList.add("hidden");
        topHeader.classList.remove("sticky-header");
    }
}
function handleSubscribeForms() {
  let subscribeButtons = document.getElementsByClassName("subscribe__span");

  if (!subscribeButtons) {
    return;
  }

  let closeButtons = document.getElementsByClassName("close-subscription-form");

  for (let subscribeButton of subscribeButtons) {
    subscribeButton.addEventListener("click", toggleSubscribeForm);
  }

  function toggleSubscribeForm(e) {
    let trigger = e.currentTarget;
    let submitButton = document.createElement("button");
    let thisForm = trigger.parentElement.querySelector(
      ".subscription-form-container"
    );

    thisForm.classList.remove("hidden");
    //appending speedy form 
    let speedyContainer = document.querySelector("#speedyAddressForm");
    speedyContainer.classList.remove("hidden");
    thisForm.querySelector(".speedy-form-container").appendChild(speedyContainer);

  }

  for (let closeButton of closeButtons) {
    closeButton.addEventListener("click", closeThisSubscribeForm);
  }

  function closeThisSubscribeForm(e) {
    let thisFormContainer = e.currentTarget.parentElement;
    thisFormContainer.classList.add("hidden");
  }

  // if the subscribe has quantity option
  let countControllers = document.getElementsByClassName("count-controller");
  for (let countController of countControllers) {
    countController.addEventListener("click", countControl);
  }

  function countControl(e) {
    let trigger = e.currentTarget;
    let increaseDecrease = parseInt(trigger.getAttribute("data-value"));
    let input = trigger.parentElement.querySelector(".subscribe-quantity");
    let value = parseInt(input.value);

    if (value + increaseDecrease < 1 || value + increaseDecrease > 20) {
      alert("Моля въведете коректна стойност");
      return;
    } else {
      input.value = value + increaseDecrease;
    }
  }


  // // handle reqired adress fields
  // let officeAdresses = document.getElementsByClassName("office-аddress");
  // let personalAdresses = document.getElementsByClassName("personal-аddress");
  // for (officeAdress of officeAdresses) {
  //   officeAdress.addEventListener('contextmenu', event => event.preventDefault());
  //   // officeAdress.addEventListener("keyup", checkRequire);

  // }

  // for (personalAdress of personalAdresses) {
  //   personalAdress.addEventListener("keyup", checkRequire);
  //   personalAdress.addEventListener('contextmenu', event => event.preventDefault());
    
  // }


}



handleSubscribeForms();

let tabsNames = document.querySelectorAll(".tabs-header .tab");
let productsTabsContainers = document.getElementsByClassName("products-tab-container");
if (tabsNames) {
  // Attaching the events
  for (tabsName of tabsNames) {
    tabsName.addEventListener("click", toggleTabs);
  }

  function toggleTabs(e) {
  
    let trigger = e.currentTarget;
    let triggerParent = trigger.parentElement;
    let triggerIndex = [].slice.call(triggerParent.children).indexOf(trigger);

    for (let i = 0; i < productsTabsContainers.length; i++) {
      tabsNames[i].classList.remove("active");
      productsTabsContainers[i].classList.add("hidden");
    }
    trigger.classList.add("active");
    productsTabsContainers[triggerIndex].classList.remove("hidden");
  }
}
